<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 557.7 349" width="52px">
<style type="text/css">
	.icon-color-red{fill:#E02A52;}
	.icon-color-yellow{fill:#FFCB05;}
	.icon-color-blue{fill:#00B8B2;}
</style>
<g id="Layer_1">
	<title>Shmoogle IO</title>
	<desc>Shmoogle IO</desc>
	<g id="Page-1">
		<g id="iPhone-8" transform="translate(-1.000000, -3.000000)">
			<g id="Group-34" transform="translate(1.000000, 3.000000)">
				<path class="icon-color-red" d="M545.3,136.1C545.3,136.1,545.3,136.1,545.3,136.1c-1.2-8-0.6-6.2-7.5-27.2
					c-26-86.4-121.9-127.7-202.5-87.4c-31.2,15.6-53.5,40.6-73,68.5c-3.2,4.6-5.5,6.8-8.3,6.2c20.2,23.1,52.9,70.7,59,79.6
					c-1.4-3.6-0.5-6.8,1.7-10.1c12.6-18.2,24.3-37.3,40.9-52.4c17.8-16.2,38.2-27.1,63-18.2c24.7,9,35.7,29.3,39.6,54.1
					c1,6.4,1.4,10.5,1.7,17.2c0.1,3.1,0.1,6.2-0.2,9.2c-1,15.3-1.7,28.7-6.9,41.7c-3.2,7.9-7.3,14.7-12.1,20.4l60.2,64.7
					c17.4-17.6,30-40.4,38.4-67.4l4.2-18.8c0.1-0.4,0.1-0.7,0.2-1.1C547.9,194.9,549.8,169,545.3,136.1z"/>
				<path class="icon-color-yellow" d="M203.2,170.9c0.2,2.6-1.9,4.7-3.7,6.8c-9.9,11.4-19.7,22.9-31.3,32.6c-14.1,11.7-29.3,19.9-47.5,11.8
					c-11.7-5.2-18.4-13.9-22-24.4l-67.6,63c0.3,0.5,0.6,1,0.8,1.5c0.1,0.2,0.2,0.4,0.3,0.6c2.3,2.7,4.6,5.3,6.8,8
					c32.2,39,72.5,53.9,122.1,40.6c35-9.4,63.5-29.1,87.2-56c3.7-4.2,6.2-6.1,9-4.4L203.2,170.9z"/>
				<path class="icon-color-blue" d="M313.1,175.8c-6.1-8.9-38.9-56.5-59-79.6c-1.8-0.4-3.7-1.9-6.2-4.8c-14.5-16.5-31.6-29.9-51.2-39.9
					C151.3,28.3,106,23.2,62.4,55.1C31.6,77.6,18,110.4,10.9,146.6c-3.7,39.9,1.2,78.3,20.1,114l67.6-63c-2-5.9-3.1-12.3-3.5-19.1
					c-0.2-2.6,0-5.3,0-5.6c-0.3-11.3,0.5-20.1,3.9-28.5c8.8-21.7,29.5-30.9,51-22.2c22.3,9,37,27,51.5,45c1.1,1.3,1.5,2.5,1.6,3.6
					l54.1,80.1c1.4,0.8,2.8,2.4,4.5,5c3.8,5.7,7.8,11.3,12,16.8l0,0.1c4.2,5.4,8.6,10.7,13.1,15.8c38.2,43.2,85.7,62.8,143,51
					c29.4-6.1,52.9-18.8,71.1-37.2l-60.2-64.7h0c-21.8,25.9-57.7,27.1-85.1-1.2c-16.7-17.4-28.6-38.4-41.5-58.6
					C313.8,177.2,313.4,176.5,313.1,175.8C313.1,175.8,313.1,175.8,313.1,175.8"/>
			</g>
		</g>
	</g>
</g>
</svg>
