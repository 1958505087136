<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1023 349" xml:space="preserve">
<style type="text/css">
	.logo-color-gray{fill:#4D4C4D;}
	.logo-color-red{fill:#E02A52;}
	.logo-color-yellow{fill:#FFCB05;}
	.logo-color-blue{fill:#00B8B2;}
</style>
<title>Shmoogle IO</title>
<desc>Shmoogle IO</desc>
<g id="Page-1">
	<g id="iPhone-8" transform="translate(-1.000000, -3.000000)">
		<g id="Group-34" transform="translate(1.000000, 3.000000)">
			<g id="Group-36">
				<path id="Fill-12" class="logo-color-gray" d="M177.8,34.9c-7.5,18.8-9.2,38.6-9.4,58.5C173.5,74.2,178.5,55,177.8,34.9 M461.4,166.5
					c-1.4,15.3,0.5,29.9,8,43.6c-4.2,12-11.3,22.2-21.8,29.1c-11,7.2-25.6,1.7-32.4-11.5c-5.9-11.6-7.5-24.2-8.9-37
					c-1.6-14.6-0.3-29.3-1-43.9c-0.2-4.4-0.9-8.5-2.8-13c-5.2,6.4-7.2,13.9-9.3,21.3c-3,10.5-4.2,21.5-6.1,32.3
					c-1.1,6.3-6.2,10.6-13,10.6c-6.5,0-11.8-4.5-13-10.7c-1.5-8-1.6-16.2-3.6-24.2c-1.2-4.9-2.9-9.5-4.5-14.2
					c-0.4-1.3-1.3-3.1-2.9-2.9c-1,0.2-2.2,1.5-2.7,2.6c-2.9,6.2-4.9,12.6-6.3,19.2c-3.5,15.8-3.9,32-5.8,48
					c-0.7,5.9-3.5,9.7-8.5,11.7c-5.5,2.2-11.9,0-15.6-4.7c-6.5-8.6-10.8-18.3-14.6-28.8c-4,8-7.5,15.7-11.5,23.1
					c-4.1,7.6-8.6,15-15.2,20.9c-10.1,9-24.1,7.9-32.7-2.6c-8.1-10-10.5-22-12.1-34.3c-2.4-17.7-1.4-35.6-1.9-53.4
					c-0.1-3.5-0.6-7-1.6-10.3c-0.4-1.1,0.2-3.2-1.6-3.2c-1,0-2.1,1.4-2.9,2.4c-3.2,4-5.7,8.6-7.8,13.3c-9.5,21.1-15,43.3-18.2,66.1
					c-1,7.2-4.5,12-10.5,13.7c-5.9,1.6-11.3-0.9-15.6-7c-9.7-13.8-14.4-29.5-17.6-45.7c-6.3-31.1-7.2-62.5-5.9-94
					c0.9-20.3,3.1-40.5,11.4-59.5c4.5-10.4,10.6-19.3,22.4-22.3c0.4-0.1,2.2-0.4,5.5-0.4l0,0c2.9,0.1,4.4,0.6,5.2,0.8
					c13.6,3.4,17.8,13.9,19.1,26.1c1.9,17.8-1.4,35.2-5.1,52.4c-3.6,16.6-8,33-13,49.2c-0.2,0.5-0.9,1.1,0.3,1.8
					c2.2-0.6,2.6-3,3.7-4.7c4.5-7.1,9.6-13.7,17.2-17.7c16.3-8.8,33.8-0.3,39,18.7c3.4,12,4,24.3,3.8,36.6
					c-0.2,13.5-0.5,27,2.1,40.3c0.5,2.8,1.3,5.4,2.7,8.5c4.8-6,7.7-12.5,10.7-18.8c8.1-17.4,15.2-35.3,20.8-53.7
					c2.1-6.9,8.3-12,14.4-11.6c7.1,0.5,12.4,6.4,13.1,14.4c0.3,3.1,0.5,6.1,0.8,10.2c3.6-4,3.9-8.3,5.6-11.9
					c4.4-9.1,9.3-17.5,19.2-21.8c10.2-4.5,21.4-2.5,29,5.8c4,4.3,4.1,4.4,6.9-0.8c3.7-6.6,8.3-12.5,15-16c13.6-7.2,29.7-1,35.8,13.3
					c3.6,8.4,5.1,17.2,5.5,26.2c0.6,12.5,0.5,25,0.4,37.5c-0.1,6.7,2,13.2,2.5,19.8c0.1,1.9,1,3.7,2.7,5.8c4.8-6,8-12.6,11.1-19.3
					c3.5-7.8,6.8-15.7,10.2-23.6C459.8,167,460.1,166,461.4,166.5"/>
				<path id="Fill-14" class="logo-color-gray" d="M63.1,0.7c5,0,7.1,0.2,10,0.9c26.3,4.6,42.4,22.4,47.8,46.5c1.9,8.5,2.7,17.1,2.4,25.8
					c-0.2,7-5.2,12.5-11.9,13.1c-7,0.7-13.4-3.7-14.5-10.8C95.6,68,96,59.7,93.6,51.6c-4.1-14.1-12.4-21.8-25.9-23.4
					c-14.1-1.7-25.9,4.1-32.8,16.2c-16.7,29.4-8.1,61.8,21.2,79.7c9.9,6.1,20.2,11.7,29.8,18.2c14.3,9.5,24.6,22.1,28.6,39.4
					c4.9,21.4-1.9,41.5-18.2,52.7c-35,23.9-82.3,3.8-89-37.8c-1.2-7.3,3.3-14.2,10.4-16c6.5-1.6,13.7,2.6,15.9,9.6
					c1.5,4.7,2.4,9.7,5.1,14c4.4,7.4,10.7,11.7,19.4,12.1c6.2,0.3,12.3,0.2,18.2-2.1c7.9-3,12.6-8.9,12.6-17.3
					c0.1-11.7-4.4-21.3-13.6-28.7c-8.9-7.1-18.9-12.3-28.7-18c-16.8-9.7-31.7-21.4-40.2-39.7C0.3,97.1-0.7,83,0.3,68.6
					C1.5,52.4,6,37.2,15.7,24.1c9-12.1,21-19.6,36-22.4C53.2,1.4,57.7,0.7,63.1,0.7L63.1,0.7z"/>
				<path class="logo-color-gray" d="M1021.2,164c-2.9-7.2-6.7-10.2-13.2-10.1c-6.6,0-10.4,3.1-13.5,10.4c-2.4,5.8-4.8,11.5-7.7,17
					c-6.6,12.7-14.6,24.5-27.8,31c-18.4,9.1-35.1,5.9-41.9-16.1c-0.6-1.8-2.3-4-0.7-5.6c1.6-1.5,3.7,0.4,5.6,0.8
					c18.3,4.4,34.9,2.3,47.9-12.6c11.8-13.6,12-29.5,6.6-45.6c-6.3-18.7-21.9-28.4-40.3-26.5c-18.1,1.9-29.9,12.3-38.7,27.3
					c-3.8,6.5-5.4,13.9-8.6,20.6l0,0c-1.9,3.5-4.1,6.8-5.7,10.4c-7.9,17-18.9,32-29.6,47.3c-3.6,5.1-4.7,4.9-8.1-0.2
					c-6.8-10.4-9.8-22.2-12.3-34c-0.9-4.2-2.4-8.3-0.6-13.1c10.4-27.7,19.1-56,25.2-85c3.5-16.6,6.6-33.4,5.3-50.5
					c-0.8-10.4-3.6-19.9-13.4-25.9c-2.7-1.7-12.8-3-15.6-2.4c-7.5,1.3-13,5.4-17.4,11.5c-5.8,8.1-9.2,17.2-11.6,26.8
					c-6.1,24.3-7,49-6.3,73.9c0.3,10.5,0.9,20.9,1.4,31.4l0,0c-6.2,17.3-14.4,33.4-28,46.7c-0.5-0.9-0.9-1.3-0.9-1.7
					c-2.3-23.3-6.6-46.5-6.3-70.1c0.1-6.1-2.7-10.7-8.4-13c-5.4-2.2-10.5-1.3-14.6,2.9c-1.4,1.5-3.6,1.8-5.5,1
					c-13.6-5.8-27.5-6.9-41.1-0.4c-14.5,7-22.7,19.6-28.2,34.1c-2,5.3-4.2,10.6-5.1,16.2c-0.1,0.7-0.2,1.4-0.3,2.1l0,0
					c1.7,12.3,1,22-0.5,29.6c0.2,1.5,0.5,3.1,0.8,4.8c0.3,1.3,0.7,3.6,1,4.9c4,13.9,12,24.8,26.1,29.3c18.5,5.9,35.5,2.6,50.4-10.3
					c2.3-2,4-4.8,7.1-5.9c0,3.6,0,3.6-2.5,6.2c-12.9,13.2-25.4,26.8-35.5,42.4c-9.8,15.3-16.9,31.4-15.5,50.1c1,13,5,24.4,17.3,31.3
					c3.4,1.9,11.8,2.9,11.8,2.9s6.6,0.5,8.1,0.5s6.2-0.5,6.2-0.5c16.3-3.9,26.5-15,32.6-30.1c5.9-14.8,7-30.4,7.7-46
					c0.6-12.8,0.4-25.6-1-38.4c-0.5-4.3,1-7.1,3.9-10.1c9.7-10,19.3-20.2,27.3-31.7l0,0c2,0.4,1.6,2.2,2.1,3.5
					c4.3,14.1,9,28.1,19.6,39.2c11.5,12,28.1,12.6,40,1.5c5-4.7,9.3-9.9,12.6-15.9c3.2-5.8,6.3-11.6,9.5-17.4
					c2.3,9.4,6.7,17.7,13.2,24.8c15.5,17,39.2,21.3,62.3,11.5c19.6-8.4,32.4-23.8,42.7-41.8c5-8.8,11-22.5,11-22.5s0.9-3.3,1.5-5.3
					C1022.2,166.7,1021.2,164,1021.2,164z M917.1,157.5c2.1-6.7,4.7-13.2,9.7-18.3c4.6-4.6,10.2-6.1,16.4-5.1
					c1.3,0.2,2.6,0.8,3.6,1.6c4.5,3.5,7,15.1,4.7,21.4c-2.2,6.1-6.9,9-14.5,9c-6.5,0.2-12.3-2.4-18.2-4.7
					C916.8,160.6,916.5,159.4,917.1,157.5z M719.4,202.2c-6,5.2-13.4,5.5-20.8,3.5c-5.1-1.4-8.2-5.4-9.2-10.3
					c-3.9-18.5-1.2-36.1,8.7-52.2c3.7-6,9.3-9.9,16.2-10c10.4,0.2,17.7,5.2,23.9,12c2.1,2.3,1,5.8,1.5,8.7
					C743.2,174.1,734.1,189.4,719.4,202.2z M745.1,304.6c-3.3,11.2-9.1,16.4-17.8,16.7c-3.8,0.1-5.7-1.2-6.5-5.2
					c-1.6-8.2-0.2-15.9,3-23.4c5.9-14,15.2-25.6,26.2-37.9C749.8,272.5,749.8,288.8,745.1,304.6z M826.5,92.7
					c0.1-19.9,1.8-39.5,9.2-58.2C836.4,54.6,831.4,73.6,826.5,92.7z"/>
				<path class="logo-color-red" d="M661.8,162.6L661.8,162.6c-0.4-3-0.2-2.3-2.8-10.2c-9.7-32.4-45.7-47.9-75.9-32.8
					c-11.7,5.8-20.1,15.2-27.4,25.7c-1.2,1.7-2.1,2.5-3.1,2.3c7.6,8.7,19.8,26.5,22.1,29.8c-0.5-1.3-0.2-2.5,0.7-3.8
					c4.7-6.8,9.1-14,15.3-19.6c6.7-6.1,14.3-10.2,23.6-6.8s13.4,11,14.8,20.3c0.4,2.4,0.5,3.9,0.6,6.4c0,1.2,0,2.3-0.1,3.5
					c-0.4,5.7-0.6,10.7-2.6,15.6c-1.2,3-2.7,5.5-4.5,7.6l22.6,24.3c6.5-6.6,11.3-15.2,14.4-25.3l1.6-7.1c0-0.1,0.1-0.3,0.1-0.4
					C662.8,184.6,663.5,174.9,661.8,162.6z"/>
				<path class="logo-color-yellow" d="M533.6,175.6c0.1,1-0.7,1.8-1.4,2.5c-3.7,4.3-7.4,8.6-11.8,12.2c-5.3,4.4-11,7.5-17.8,4.4
					c-4.4-2-6.9-5.2-8.3-9.2L469,209.3c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0.1,0.2,0.1,0.2c0.8,1,1.7,2,2.6,3
					c12.1,14.6,27.2,20.2,45.8,15.2c13.1-3.5,23.8-10.9,32.7-21c1.4-1.6,2.3-2.3,3.4-1.7L533.6,175.6z"/>
				<path class="logo-color-blue" d="M574.8,177.4c-2.3-3.3-14.6-21.2-22.1-29.8c-0.7-0.2-1.4-0.7-2.3-1.8c-5.4-6.2-11.9-11.2-19.2-15
					c-17-8.7-34-10.6-50.4,1.4c-11.5,8.4-16.6,20.7-19.3,34.3c-1.4,15,0.4,29.4,7.5,42.8l25.4-23.6c-0.8-2.2-1.2-4.6-1.3-7.1
					c-0.1-1,0-2,0-2.1c-0.1-4.2,0.2-7.5,1.5-10.7c3.3-8.1,11-11.6,19.1-8.3c8.4,3.4,13.9,10.1,19.3,16.9c0.4,0.5,0.6,0.9,0.6,1.3
					l20.3,30c0.5,0.3,1,0.9,1.7,1.9c1.4,2.1,2.9,4.2,4.5,6.3l0,0c1.6,2,3.2,4,4.9,5.9c14.3,16.2,32.1,23.6,53.6,19.1
					c11-2.3,19.8-7,26.6-13.9l-22.6-24.3l0,0c-8.2,9.7-21.6,10.2-31.9-0.5c-6.3-6.5-10.7-14.4-15.6-22
					C575,178,574.9,177.7,574.8,177.4C574.8,177.5,574.8,177.4,574.8,177.4"/>
			</g>
		</g>
	</g>
</g>
</svg>
