import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-atb-icon',
  templateUrl: './add-to-boards-icon.component.html',
})
export class AddToBoardsIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
