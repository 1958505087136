<div class="dropDownList" *ngIf="user">
  <div class="inputField d-flex" *ngIf="!addedToBoard; else addDone">
    <app-atb-icon></app-atb-icon>
    <mat-form-field floatLabel="never">
      <mat-label>Save to a Board</mat-label>
      <mat-select 
        (selectionChange)="onChange($event)">
        <ng-container *ngFor="let item of boards">
          <mat-option [value]="item.id">{{ item.title }}</mat-option>
        </ng-container>
        <mat-option value="new">Add to new board</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-template #addDone>
    <span class="d-flex pt-1 saved">
      <mat-icon>done</mat-icon>
      Added to board!
    </span>
  </ng-template>
</div>